<template>
  <div>
    <div v-if="isLoading">
      <CanPayLoader/>
    </div>
    <div v-if="!isLoading">
      <div>
        <!-------------------------- SEARCH BAR------------------->
        <div>
          <div class="collapse" id="collapseExample">
            <div>
              <div
                class="row"
                id="row-header"
                style="width: 100%; margin-left: 15px"
              >
                <div
                  class="col-10 col-md-11"
                  style="
                    background-color: rgb(236, 236, 236);
                    border-radius: 5px;
                  "
                >
                  <div
                    style="
                      z-index: 999999 !important;
                      padding: 0px;
                      height: 50px;
                      float: left;
                      width: 100%;
                      display: table;
                    "
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 100 125"
                      style="
                        enable-background: new 0 0 100 125;
                        margin-top: 13px;
                        margin-left: -10px;
                      "
                      xml:space="preserve"
                      height="30"
                      width="30"
                    >
                      <path
                        d="M90.4,84L75.8,69.5C87.2,54.9,86,34.1,72.9,21C66,14.1,56.8,10.3,47,10.3S28,14.1,21.1,21S10.4,37.1,10.4,46.9
	s3.8,19,10.7,25.9S37.2,83.5,47,83.5c8.1,0,16.1-2.7,22.5-7.8L84,90.4c0.9,0.9,2,1.3,3.2,1.3s2.3-0.5,3.2-1.3
	C92.2,88.7,92.2,85.8,90.4,84z M74.6,46.9c0,7.4-2.9,14.3-8.1,19.5S54.3,74.5,47,74.5s-14.3-2.9-19.5-8.1s-8.1-12.2-8.1-19.5
	c0-7.4,2.9-14.3,8.1-19.5s12.2-8.1,19.5-8.1c7.4,0,14.3,2.9,19.5,8.1S74.6,39.5,74.6,46.9z"
                      />
                    </svg>
                    <div
                      class="input-group"
                      style="
                        border: none !important;
                        border-color: transparent !important;
                        width: 90% !important;
                        float: right;
                      "
                    >
                      <autocomplete
                        ref="autocomplete"
                        placeholder="Search"
                        :source="searchStore"
                        input-class="form-control search-background"
                        results-property="data"
                        :results-display="formattedDisplay"
                        :request-headers="authHeaders"
                        @selected="getStoreDetails"
                      ></autocomplete>
                      <!-- </gmap-autocomplete> -->
                    </div>
                  </div>
                </div>
                <div class="col-2 col-md-1" style="float: right">
                  <a v-on:click="clearSearch">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 100 125"
                      style="
                        enable-background: new 0 0 100 125;
                        margin-top: 15px;
                        margin-right: 15px;
                      "
                      xml:space="preserve"
                      height="15"
                      width="15"
                    >
                      <g>
                        <path
                          d="M85.7,14.3c-2.3-2.3-6.1-2.3-8.5,0L50,41.5L22.7,14.3c-2.3-2.3-6.1-2.3-8.5,0c-2.3,2.3-2.3,6.1,0,8.5L41.5,50L14.3,77.3
		c-2.3,2.3-2.3,6.1,0,8.5c1.2,1.2,2.7,1.8,4.2,1.8s3.1-0.6,4.2-1.8L50,58.5l27.3,27.3c1.2,1.2,2.7,1.8,4.2,1.8s3.1-0.6,4.2-1.8
		c2.3-2.3,2.3-6.1,0-8.5L58.5,50l27.3-27.3C88.1,20.4,88.1,16.6,85.7,14.3z"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>

              <br />
            </div>
          </div>
        </div>

        <!--------------------------------->
        <!-- Map section -->
        <GmapMap
          ref="mapRef"
          :center="center"
          :zoom="10"
          map-type-id="terrain"
          style="width: 100%; height: 400px; margin-top: -7px"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            @click="handleMarkerClick(m.id)"
            :icon="m.position.icon"
          />
        </GmapMap>
      </div>
      <tabs
        :tabs="tabs"
        :currentTab="currentTab"
        :wrapper-class="'default-tabs bottom-edge-shadow'"
        :tab-class="'default-tabs__item'"
        :tab-active-class="'default-tabs__item_active'"
        :line-class="'default-tabs__active-line'"
        @onClick="changeTab"
      />
      <div class="tab-content store-list-card">
        <!--------------------------------------------------------------------------->
        <!------------------------------------ TAB 1 --------------------------------->
        <!------------------------------------------------------------------------------>

        <div v-if="currentTab === 'nearby'">
          <div class="white-body">
            <div class="card content-div" v-if="!storeList.length">
              <div class="no-store-style" id="headingTwo">
                <div class="row justify-content-center align-self-center">
                  <div class="col-sm-6">
                    <label>
                      <b>No Stores Available!</b>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-auto history-div" v-if="storeList.length">
            <div
              class="accordion accordian-style"
              id="accordionExample"
              v-for="(terminals, index) in storeList"
              :key="index"
            >
              <div
                :id="'nearby-store-card-' + terminals.id"
                v-bind:class="[
                  terminals.is_generic == 1
                    ? 'card content-div side-green'
                    : 'card content-div side-yellow',
                  '',
                ]"
              >
                <div
                  class="card-header"
                  id="headingTwo"
                  v-on:click="clickOnStore(terminals)"
                  style="
                    margin-left: 10px;
                    background-color: #ffffff;
                    border-bottom: transparent !important;
                  "
                >
                  <div class="row">
                    <div class="col-10" style="padding-left: 0px !important">
                      <label
                        class="transaction-amount-heading"
                        style="text-align: left"
                        >{{ terminals.retailer }}</label
                      >
                    </div>
                  </div>
                  <div class="row h-25">
                    <label class="store-name-label">{{
                      storeAddress(terminals)
                    }}</label>
                  </div>

                  <div
                    class="row h-25"
                    style="
                      margint-top: 5px;
                      margint-bottom: 5px;
                      margin-top: 5px;
                    "
                  >
                    <div
                      class="col-10"
                      style="padding-right: 0px; padding-left: 0px"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 307 495"
                        style="
                          enable-background: new 0 0 307 495;
                          float: left !important;
                        "
                        xml:space="preserve"
                        height="20"
                        width="20"
                        fill="#7f7f7f"
                      >
                        <g>
                          <path
                            d="M153,0c85,0,154,69,154,153c0,88-90,191-154,243C90,344,0,241,0,153C0,69,69,0,153,0z M153,27C84,27,27,84,27,153
		c0,71,76,164,126,208c51-44,127-137,127-208C280,84,223,27,153,27z"
                          />
                          <path
                            d="M153,88c36,0,66,30,66,65c0,36-30,66-66,66c-35,0-65-30-65-66C88,118,118,88,153,88z M153,115c-21,0-38,17-38,38
		c0,22,17,38,38,38c22,0,38-16,38-38C191,132,175,115,153,115z"
                          />
                        </g>
                      </svg>

                      <label
                        class="Open-style"
                        style="color: #7f7f7f; margin-left: 3px !important"
                        >{{
                          distance(
                            terminals.lat,
                            terminals.long,
                            currentLat,
                            currentLong,
                            ""
                          )
                        }}</label
                      >
                    </div>
                    <!----1-->
                    <div
                      class="col-10"
                      style="padding-right: 0px; padding-left: 0px"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 24 30"
                        style="enable-background: new 0 0 24 30; float: left"
                        xml:space="preserve"
                        height="18"
                        width="18"
                        fill="#7f7f7f"
                      >
                        <path
                          class="st0"
                          d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10
	s10-4.5,10-10S17.5,2,12,2z M14.2,15.7l-2.9-2.9C11.1,12.6,11,12.3,11,12V7c0-0.6,0.4-1,1-1s1,0.4,1,1v4.5l2.7,2.7
	c0.4,0.4,0.4,1.1,0,1.5C15.3,16.1,14.6,16.1,14.2,15.7L14.2,15.7z"
                        />
                      </svg>
                      <label v-if="terminals.timing_arr" class="Open-time-text-style">{{
                        getOpeningHoursStatus(terminals)
                      }}</label>
                      <label v-if="!terminals.timing_arr" class="not-available-time-text-style">{{
                        getOpeningHoursStatus(terminals)
                      }}</label>
                      <label v-if="terminals.timing_arr" class="Open-style"
                        >{{ getOpeningHoursTiming(terminals) }}</label
                      >
                    </div>
                    <!---3-->
                    <div class="col-2" style="float: right">
                      <a v-on:click="clickOnStore(terminals)">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 2540 3175"
                          style="
                            enable-background: new 0 0 2540 3175;
                            -webkit-transform: scaleX(-1);
                            transform: scaleX(-1);
                          "
                          xml:space="preserve"
                          height="20"
                          width="20"
                          fill="#7f7f7f"
                        >
                          <g>
                            <path
                              d="M1878,2270c39,39,39,104,0,143c-40,40-104,40-144,0L662,1342c-39-40-39-104,0-144L1734,127c40-40,104-40,144,0
		c39,39,39,104,0,143L878,1270L1878,2270z"
                            />
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--------------------------------------------------------------------------->
        <!------------------------------------ TAB 2 --------------------------------->
        <!------------------------------------------------------------------------------>
        <div v-if="currentTab === 'all'">
          <div class="mx-auto history-div">
            <div
              class="accordion accordian-style"
              id="accordionExample"
              v-for="(terminals, index) in storeList"
              :key="index"
            >
              <div
                v-bind:class="[
                  terminals.is_generic == 1
                    ? 'card content-div side-green'
                    : 'card content-div side-yellow',
                  '',
                ]"
                style="height: 400px !important"
                v-if="!storeList.length"
              >
                <div
                  class="card-header"
                  id="headingTwo"
                  style="
                    margin-left: 10px;
                    background-color: #ffffff;
                    border-bottom: transparent !important;
                  "
                >
                  <div class="row">
                    <div
                      class="col-9 col-md-10"
                      style="padding-left: 0px !important; padding-right: 0px"
                    >
                      <label
                        class="transaction-amount-heading"
                        style="text-align: left"
                        >No Stores Available!</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                :id="'all-store-card-' + terminals.id"
                v-if="storeList.length"
                v-bind:class="[
                  terminals.is_generic == 1
                    ? 'card content-div side-green'
                    : 'card content-div side-yellow',
                  '',
                ]"
              >
                <div
                  class="card-header"
                  id="headingTwo"
                  v-on:click="clickOnStore(terminals)"
                  style="
                    margin-left: 10px;
                    background-color: #ffffff;
                    border-bottom: transparent !important;
                  "
                >
                  <div class="row">
                    <div class="col-10" style="padding-left: 0px !important">
                      <label
                        class="transaction-amount-heading"
                        style="text-align: left"
                        >{{ terminals.retailer }}</label
                      >
                    </div>
                  </div>

                  <div class="row h-25">
                    <label class="store-name-label">{{
                      storeAddress(terminals)
                    }}</label>
                  </div>
                  <div
                    class="row h-25"
                    style="
                      margint-top: 5px;
                      margint-bottom: 5px;
                      margin-top: 5px;
                    "
                  >
                    <div
                      class="col-10"
                      style="padding-right: 0px; padding-left: 0px"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 307 495"
                        style="
                          enable-background: new 0 0 307 495;
                          float: left !important;
                        "
                        xml:space="preserve"
                        height="20"
                        width="20"
                        fill="#7f7f7f"
                      >
                        <g>
                          <path
                            d="M153,0c85,0,154,69,154,153c0,88-90,191-154,243C90,344,0,241,0,153C0,69,69,0,153,0z M153,27C84,27,27,84,27,153
		c0,71,76,164,126,208c51-44,127-137,127-208C280,84,223,27,153,27z"
                          />
                          <path
                            d="M153,88c36,0,66,30,66,65c0,36-30,66-66,66c-35,0-65-30-65-66C88,118,118,88,153,88z M153,115c-21,0-38,17-38,38
		c0,22,17,38,38,38c22,0,38-16,38-38C191,132,175,115,153,115z"
                          />
                        </g>
                      </svg>

                      <label
                        class="Open-style"
                        style="color: #7f7f7f; margin-left: 3px !important"
                        >{{
                          distance(
                            terminals.lat,
                            terminals.long,
                            currentLat,
                            currentLong,
                            ""
                          )
                        }}</label
                      >
                    </div>
                    <!----1-->
                    <div
                      class="col-10"
                      style="padding-right: 0px; padding-left: 0px"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 24 30"
                        style="enable-background: new 0 0 24 30; float: left"
                        xml:space="preserve"
                        height="18"
                        width="18"
                        fill="#7f7f7f"
                      >
                        <path
                          class="st0"
                          d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10
	s10-4.5,10-10S17.5,2,12,2z M14.2,15.7l-2.9-2.9C11.1,12.6,11,12.3,11,12V7c0-0.6,0.4-1,1-1s1,0.4,1,1v4.5l2.7,2.7
	c0.4,0.4,0.4,1.1,0,1.5C15.3,16.1,14.6,16.1,14.2,15.7L14.2,15.7z"
                        />
                      </svg>
                      <label v-if="terminals.timing_arr" class="Open-time-text-style">{{
                        getOpeningHoursStatus(terminals)
                      }}</label>
                      <label v-if="!terminals.timing_arr" class="not-available-time-text-style">{{
                        getOpeningHoursStatus(terminals)
                      }}</label>
                      <label v-if="terminals.timing_arr" class="Open-style"
                        >{{ getOpeningHoursTiming(terminals) }}</label
                      >
                    </div>
                    <!---3-->

                    <div class="col-2" style="float: right">
                      <a v-on:click="clickOnStore(terminals)">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 2540 3175"
                          style="
                            enable-background: new 0 0 2540 3175;
                            -webkit-transform: scaleX(-1);
                            transform: scaleX(-1);
                          "
                          xml:space="preserve"
                          height="20"
                          width="20"
                          fill="#7f7f7f"
                        >
                          <g>
                            <path
                              d="M1878,2270c39,39,39,104,0,143c-40,40-104,40-144,0L662,1342c-39-40-39-104,0-144L1734,127c40-40,104-40,144,0
		c39,39,39,104,0,143L878,1270L1878,2270z"
                            />
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-------------------------- FILTER MODAL ---------------------------->
      <div>
        <b-modal
          ref="filter-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          id="pay-modal-center"
          centered
          title="BootstrapVue"
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="row">
                <div class="col-2">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns:cc="http://creativecommons.org/ns#"
                    xmlns:dc="http://purl.org/dc/elements/1.1/"
                    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                    xmlns:svg="http://www.w3.org/2000/svg"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 20"
                    style="enable-background: new 0 0 16 20"
                    xml:space="preserve"
                    height="35"
                    width="35"
                  >
                    <g transform="translate(0,-1036.3622)">
                      <g transform="translate(78,-251.00067)">
                        <g transform="translate(27.506945,-29.633208)">
                          <path
                            class="st0"
                            d="M-102,1320C-102,1320-102,1320-102,1320C-102.1,1320-102.1,1320-102,1320c-0.3,0-0.5,0.3-0.5,0.5
				c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0l3.8,3.8v4.3c0,0.2,0.1,0.4,0.3,0.4l2,1c0.2,0.1,0.5,0,0.7-0.2c0-0.1,0.1-0.1,0.1-0.2v-5.3
				l3.8-3.8c0.2-0.2,0.2-0.5,0.1-0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0L-102,1320C-102,1320-102,1320-102,1320z
				 M-100.8,1321h7.6l-3.1,3.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.2v4.6l-1-0.5v-4.1c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.1-0.2L-100.8,1321z"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="col-9" style="padding-left: 5px; margin-top: 3px">
                  <label class="store-address">Filter</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12">
                  <div
                    class="radio"
                    v-for="(options, index) in listOptions"
                    :key="index"
                  >
                    <div class="row">
                      <div class="col-10 col-md-10">
                        <label for="one" class="filter-form store-contact">
                          {{ options.value }}
                        </label>
                      </div>
                      <div class="col-2 col-md-2">
                        <input
                          type="radio"
                          :value="options.id"
                          v-model="listModel"
                          @change="filterStores(options.value)"
                          fill="#1b9142"
                          style="margin-top: 25px !important"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "vuejs-auto-complete";
import moment from "moment";
import Tabs from "vue-tabs-with-active-line";
import MarkerClusterer from "marker-clusterer-plus";
import api from "../../api/participantmerchant.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue";
const TABS = [
  {
    title: "Nearby",
    value: "nearby",
  },
  {
    title: "All",
    value: "all",
  }
];
const properties = ["zoom", "periods", "favourite", "opening_hours", "day_array", "opens_at", "closes_at", "isLoading", "searchPlace", "store_type", "favcolor", "listModel", "tabs", "currentTab", "storeList", "center", "markers", "places", "currentPlace", "currentLat", "currentLong", "authHeaders", "listOptions", "currentUser", "markerOptions", "storeId"];
const mapDefaultMarker = require("../../assets/images/Default_marker.png");
const mapMerchantMarker = require("../../assets/images/Merchant_marker.png");
const currentLocationMarker = require("../../assets/images/User_location.png");
export default {
  name: "RetailerLocator",
  components: {
    Tabs,
    Autocomplete,
    Loading,
    CanPayLoader
  },
  data: () => ({
    zoom: 7,
    periods: [],
    opening_hours: [],
    day_array: [
      { key: 1, name: "Mon" },
      { key: 2, name: "Tue" },
      { key: 3, name: "Wed" },
      { key: 4, name: "Thu" },
      { key: 5, name: "Fri" },
      { key: 6, name: "Sat" },
      { key: 7, name: "Sun" },
    ],
    opens_at: "",
    closes_at: "",
    isLoading: false,
    searchPlace: "",
    store_type: "nearby",
    favcolor: "#000000",
    listModel: "",
    currentLat: "",
    currentLong: "",
    tabs: TABS,
    currentTab: "nearby",
    storeList: [],
    center: {},
    markers: [],
    places: [],
    currentPlace: null,
    authHeaders: {
      Authorization: localStorage.getItem("consumer_token"),
    },
    listOptions: [
      {
        id: 0,
        value: "All Locations",
      },
      {
        id: 1,
        value: "Open",
      },
    ],
    markerOptions: {},
    storeId: '',
  }),
  methods: {
    //search store name from search bar
    searchStore(input) {
      return process.env.VUE_APP_API_URL + "/stores/search?keyword=" + input;
    },
    //getting store details after searching from search bar
    getStoreDetails(store) {
      var self = this;
      self.isLoading = true;
      var request = {
        id: store.value,
      };
      api
        .getStoreDetails(request)
        .then((response) => {
          self.isLoading = false;
          if (response.code == 200) {
            self.storeList = [];
            self.storeList.push(response.data);
            self.currentTab = "nearby";
            self.markers = [];
            if (response.data.lat != null && response.data.long != null) {
              self.addMarker(response.data.lat, response.data.long);
            }
          }
        })
        .catch(function (error) {
          self.isLoading = false;
        });
    },
    //showing the formatted store name in the suggestion list of the search bar
    formattedDisplay(result) {
      return result.name;
    },
    //returns the store address
    storeAddress(terminals) {
      var full_address = "";
      full_address =
        full_address +
        (terminals.address != null ? terminals.address + " " : "");
      full_address =
        full_address + (terminals.city != null ? terminals.city + " " : "");
      full_address =
        full_address + (terminals.county != null ? terminals.county + " " : "");
      full_address =
        full_address + (terminals.state != null ? terminals.state + " " : "");
      full_address =
        full_address + (terminals.zip != null ? terminals.zip + " " : "");
      return full_address;
    },
    /**
     * functions to show and hide the filter modal
     */
    showModal() {
      var self = this;
      self.$refs["filter-modal"].show();
    },
    hideModal() {
      this.$refs["filter-modal"].hide();
    },
    //when clicked on store arrow it should redirect to store details page
    clickOnStore(data) {
      let self = this;
      self.storeId = data.id;
      const stateToSave = {};
      properties.forEach(prop => {
          stateToSave[prop] = this[prop]; // Save each property
      });
      localStorage.setItem("details", JSON.stringify(data));
      localStorage.setItem("last_visited_tab", self.currentTab);
      self.$router.push("/retailerdetails");
    },
    //changes the tab content
    changeTab(newTab) {
      var self = this;
      self.$refs.autocomplete.clear();
      self.currentTab = newTab;
      self.getallStores(newTab);
    },
    addMarker(lat, lng, storeId) {
      var self = this;
      const marker = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        icon: self.markerOptions,
      };
      self.markers.push({ position: marker, id: storeId });
      self.center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
    },
    clearSearch() {
      this.$refs.autocomplete.clear();
    },
    distance(lat1, lon1, lat2, lon2, unit) {
      if (lat1 == lat2 && lon1 == lon2) {
        return 0;
      } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") {
          dist = dist * 1.609344;
        }
        if (unit == "N") {
          dist = dist * 0.8684;
        }
        return dist.toFixed(1) + " mi";
      }
    },

    getallStores(storetype) {
      let self = this;
      self.isLoading = true;
      self.center.lat = parseFloat(localStorage.getItem("current_lat"));
      self.center.lng = parseFloat(localStorage.getItem("current_long"));
      if (localStorage.getItem("last_visited_tab") != null) {
        storetype = localStorage.getItem("last_visited_tab");
        self.currentTab = localStorage.getItem("last_visited_tab");
        localStorage.removeItem("last_visited_tab");
      }
      var request = {
        type: storetype,
        lat: parseFloat(this.center.lat),
        long: parseFloat(this.center.lng),
      };
      api
        .getstorelistingforConsumer(request)
        .then((response) => {
          self.markers = [];
          self.markerOptions = {
            url: currentLocationMarker,
            size: { width: 30, height: 30, f: "px", b: "px" },
            scaledSize: { width: 30, height: 30, f: "px", b: "px" },
          };
          
          if (self.currentLat != null && self.currentLong != null && process.env.VUE_APP_CURRENT_LOCATION == 'enable') {
            self.addMarker(self.currentLat, self.currentLong, 'current_location');
          }
          
          if (response.data.length == 0) {
            self.storeList = response.data;
            self.isLoading = false;
          } else {
            var iconPath = mapDefaultMarker;
            response.data.forEach(function (item) {
              iconPath = item.is_generic == 0 ? mapMerchantMarker : mapDefaultMarker;
              self.markerOptions = {
                url: iconPath,
                size: { width: 30, height: 35, f: "px", b: "px" },
                scaledSize: { width: 30, height: 35, f: "px", b: "px" },
              };
              if (item.lat != null && item.long != null) {
                self.addMarker(item.lat, item.long, item.id);
              }
            });
            setTimeout(function () {
              self.storeList = response.data;
              self.center = {
                lat: parseFloat(response.data[0].lat),
                lng: parseFloat(response.data[0].long),
              };
              self.isLoading = false;
            }, 300);
          }
        })
        .catch(function (error) {});
    },

    filterStores(value) {
      var self = this;
      self.$refs["filter-modal"].hide();
      self.isLoading = true;
      self.currentTab = "all";
      if (value == "Open") {
        var stores = self.storeList;
        self.storeList = [];
        var temp = [];
        stores.forEach(function (item) {
          var opening_status = self.opening_hours.find((p) => p.id == item.id);
          if (typeof opening_status != "undefined") {
            if (opening_status.isopen) {
              temp.push(item);
            }
          }
        });
        self.storeList = temp;
        self.isLoading = false;
      } else {
        self.getallStores("all");
      }
    },
    // returns the opening time of a store
    getOpeningTime(day) {
      if (day != 7) {
        var res = null;
        this.periods.every(function (item) {
          if (item.open.day >= day) {
            res = item;
            return false;
          } else {
            return true;
          }
        });
        if (res == null) {
          this.getOpeningTime(7);
        } else {
          var day_name = this.day_array.find((p) => p.key == res.open.day);
          this.opens_at =
            day_name.name +
            " " +
            moment(res.open.hours + ":" + res.open.minutes, "HH:mm").format(
              "hh:mm a"
            );
        }
      } else {
        var day_name = this.day_array.find(
          (p) => p.key == this.periods[0].open.day
        );
        this.opens_at =
          day_name.name +
          " " +
          moment(
            this.periods[0].open.hours + ":" + this.periods[0].open.minutes,
            "HH:mm"
          ).format("hh:mm a");
      }
    },
    getOpeningHoursStatus(store) {
      if (store.status) {
        return store.status.split('-')[0];
      } else {
        return "Hours currently unavailable. Contact the merchant";
      }
    },
    getOpeningHoursTiming(store) {
      if (store.status) {
        return '- '+ store.status.split('-')[1];
      } else {
        return "";
      }
    },
    getScrollableParent(node) {
      if (node == null) {
        return null;
      }

      const overflowY = window.getComputedStyle(node).overflowY;
      const isScrollable = (overflowY === 'auto' || overflowY === 'scroll') && node.scrollHeight > node.clientHeight;

      if (isScrollable) {
        return node;
      }

      return this.getScrollableParent(node.parentNode) || document.documentElement; // Fallback to document
    },
    handleMarkerClick(storeId) {
      var self = this;
      // Center the map to the marker's position (this assumes that `storeId` corresponds to a marker with position info)
      const marker = this.markers.find(marker => marker.id === storeId);
      if (marker) {
        this.center = marker.position;
      }

      // Scroll to the store card in both tabs
      this.$nextTick(() => {
        let card;
        if(self.currentTab == 'all') {
          card = document.getElementById(`all-store-card-${storeId}`);
        } else {
          card = document.getElementById(`nearby-store-card-${storeId}`);
        }
        if (card) {
          const scrollableParent = this.getScrollableParent(card);

          if (scrollableParent) {
            // Calculate the card's position relative to the scrollable parent
            const cardRect = card.getBoundingClientRect();
            const parentRect = scrollableParent.getBoundingClientRect();

            // Offset of 50px from the top
            const offset = 50;

            // Calculate how much to scroll by checking the card's top relative to the parent
            const scrollTo = cardRect.top - parentRect.top + scrollableParent.scrollTop - offset;

            // Scroll the parent to the desired position
            scrollableParent.scrollTo({
              top: scrollTo,
              behavior: 'smooth'
            });
          }
        }
      });
    }
  },
  mounted() {
    let self = this;
    this.$root.$emit("show_header", 2);
    self.$root.$on("fliterOn", function (data) {
      setTimeout(function () {
        self.showModal();
      }, 300);
    });
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    //getting the the allow location permission
    navigator.geolocation.getCurrentPosition((position) => {
      self.center = {
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
      };
      self.currentLat = self.center.lat;
      self.currentLong = self.center.lng;
      localStorage.setItem("current_lat", parseFloat(self.center.lat));
      localStorage.setItem("current_long", parseFloat(self.center.lng));
      self.getallStores("nearby");
    });
  },
  created() {},
};
</script>

<style lang="scss">
.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}
@media only screen and ( min-width:270px) and ( max-width:700px) {
  .not-available-time-text-style{
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    letter-spacing: normal;
    color: #666666;
    float: left;
    padding-left: 23px;
    margin-top: -23px;
    color: #1b9142;
    text-align: left;
  }
}

@media only screen and ( min-width:600px) {
  .not-available-time-text-style{
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    letter-spacing: normal;
    color: #666666;
    float: left;
    padding-left: 6px;
    margin-top: -7px;
    color: #1b9142;
    text-align: left;
  }
}
.store-list-card {
  height: 400px; /* Adjust height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}
.card {
  transition: opacity 0.3s ease, height 0.3s ease; /* Smooth transitions */
}

.card.hidden {
  opacity: 0; /* Hide card */
  height: 0; /* Optional: reduce height to zero */
  overflow: hidden; /* Hide overflow content */
}

/* Optional: styling for the selected card */
.card.selected {
  opacity: 1; /* Fully visible */
  height: auto; /* Reset height */
}

.default-tabs.bottom-edge-shadow {
  position: sticky;
  top: 0; /* Stick the tabs to the top when scrolling */
  z-index: 10; /* Ensure tabs stay above the content */
}
</style>
